<template>
  <div class="course-member-container">
    <!-- navbar -->
    <QbNavbar title="会员模式"></QbNavbar>
    <!-- title -->
    <QbTitle
      title="会员模式"
      subtitle="Quanbang Group Membership model"
    ></QbTitle>
    <!-- member-video -->
    <MemberVideo></MemberVideo>
    <!-- member-lecturer -->
    <MemberLecturer></MemberLecturer>
    <!-- member-reserve -->
    <MemberReserve></MemberReserve>
    <!-- member-schedule -->
    <MemberSchedule></MemberSchedule>
    <!-- member-content -->
    <MemberContent></MemberContent>
    <!-- member-comslusion -->
    <MemberConslusion></MemberConslusion>
    <!-- qb-application -->
    <qb-application id="member-application"></qb-application>
  </div>
</template>

<script>
import MemberVideo from './components/member-video.vue'
import MemberLecturer from './components/member-lecturer.vue'
import MemberReserve from './components/member-reserve.vue'
import MemberSchedule from './components/member-schedule.vue'
import MemberContent from './components/member-content.vue'
import MemberConslusion from './components/member-conslusion.vue'
export default {
  name: 'CourseMember',
  components: {
    MemberVideo,
    MemberLecturer,
    MemberReserve,
    MemberSchedule,
    MemberContent,
    MemberConslusion
  },
  data() {
    return {}
  }
}
</script>

<style lang="less" scoped></style>
