<template>
  <div class="member-content-container">
    <h3 class="title">会员模式能帮助你什么？</h3>
    <ul class="can">
      <li>《会员模式》最终会帮你实现：</li>
      <li>1.从投资转向融资做生意，让你不花一分钱开店。</li>
      <li>2.从重资产运营转向轻资产运营。</li>
      <li>3.把公司越做越小，把市场越做越大。</li>
    </ul>
    <ul class="box1">
      <li>教</li>
      <li>你</li>
      <li>如</li>
      <li>何</li>
    </ul>
    <div class="box2">
      <span>拓</span>
      <span>留</span>
      <span>锁</span>
      <span>升</span>
    </div>
    <div class="triangle"></div>
    <ul class="box3">
      <li>快速拓客引流客户</li>
      <li>快速留住精准客户</li>
      <li>锁定客户持续消费</li>
      <li>将客户升级为你的股东</li>
    </ul>
    <ul class="box4">
      <li>
        <div class="num">ONE</div>
        <div class="info">财富裂变</div>
      </li>
      <li>
        <div class="num">TWO</div>
        <div class="info">人才裂变</div>
      </li>
      <li>
        <div class="num">THREE</div>
        <div class="info">渠道裂变</div>
      </li>
      <li>
        <div class="num">FOUR</div>
        <div class="info">时间裂变</div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'MemberContent'
}
</script>

<style lang="less" scoped>
.member-content-container {
  padding: 0 20px;
  .title {
    font-size: 24px;
    font-weight: 500;
    color: #010343;
    line-height: 33px;
    margin-bottom: 25px;
  }
  .can {
    font-size: 14px;
    font-weight: 400;
    color: #010343;
    line-height: 31px;
    margin-bottom: 46px;
  }
  .box1 {
    display: flex;
    justify-content: space-between;
    li {
      width: 64px;
      height: 64px;
      line-height: 64px;
      text-align: center;
      background: #1651dd;
      font-size: 24px;
      font-weight: 700;
      color: #ffffff;
    }
  }
  .box2 {
    width: 335px;
    height: 64px;
    line-height: 64px;
    background: #f7f9fd;
    margin-top: 28px;
    font-size: 24px;
    font-weight: 700;
    color: #010343;
    letter-spacing: 54px;
    padding-left: 41px;
    span:last-of-type {
      letter-spacing: 0px;
    }
  }
  .triangle {
    width: 0px;
    height: 0px;
    border-width: 30px 12px 30px 20px;
    border-style: solid;
    border-color: white white white #1651dd;
    transform: rotate(90deg);
    margin: 0px auto;
  }
  .box3 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 74px;

    li {
      width: 64px;
      height: 357px;
      background: #f7f9fd;
      padding: 19px 20px 0;
      font-size: 24px;
      font-weight: 400;
      color: #010343;
      line-height: 33px;
    }
  }
  .box4 {
    li {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
    }
    .num {
      width: 50px;
      height: 50px;
      background: #1651dd;
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;
      line-height: 50px;
      text-align: center;
    }
    .info {
      width: 277px;
      height: 50px;
      background: #f7f9fd;
      font-size: 18px;
      font-weight: 500;
      color: #010343;
      line-height: 50px;
      text-align: center;
    }
  }
}
</style>
