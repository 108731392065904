<template>
  <div class="reserve-container">
    <div class="logo"></div>
    <div class="title">会员模式 裂变推动</div>
    <div class="introdution">
      如何最大化的保有老客户，转化新客户？如何精准分析客户潜在消费机会？如何挖掘客户持续消费力，培育客户终身价值？<br /><br />
      针对于这些企业难题，需要有一套完善的会员模式来实现。那么，会员模式如何建立？中小企业如何打造独具特色的会员模式，实现企业快速发展？这一切，还需要《会员模式》课程！
    </div>
    <div class="btn" @click="handleGoApplication"></div>
  </div>
</template>

<script>
export default {
  name: 'MemberReserve',
  methods: {
    handleGoApplication() {
      document.querySelector('#member-application').scrollIntoView()
    }
  }
}
</script>

<style lang="less" scoped>
.reserve-container {
  width: 375px;
  height: 389px;
  background: #f7f9fd;
  padding: 80px 50px 0 48px;
  .logo {
    width: 26px;
    height: 28px;
    background: url(~@/assets/images/fission/reserve-logo.png);
    background-size: cover;
    margin-bottom: 10px;
  }
  .title {
    font-size: 23px;
    font-weight: 500;
    color: #010343;
    line-height: 32px;
    margin-bottom: 33px;
  }
  .introdution {
    width: 277px;
    font-size: 10px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #010343;
    line-height: 20px;
    margin-bottom: 25px;
  }
  .btn {
    width: 104px;
    height: 33px;
    background: url(~@/assets/images/fission/reserve-btn.png);
    background-size: cover;
  }
}
</style>
