<template>
  <div class="member-schedule-container">
    <h3 class="title">会员模式课程安排</h3>
    <div class="under-line"></div>
    <div class="content">
      <div class="time-line"></div>
      <div class="arrange">
        <ul class="mb72">
          <li class="mb">
            <div class="time">7:30-8:30</div>
            <div class="arrange-content">
              带好个人身份证与学习顾问联系进行签到
            </div>
          </li>
          <li class="mb">
            <div class="time">8:30-12:00</div>
            <div class="arrange-content">
              8:30开始入场，8:50开始上午课程
            </div>
          </li>
          <li class="mb">
            <div class="time">13:50-18:00</div>
            <div class="arrange-content">
              13:50下午课程入场，14:00开始下午课程
            </div>
          </li>
          <li>
            <div class="time">19:00-21:00</div>
            <div class="arrange-content">19:00入场，并开始晚上课程</div>
          </li>
        </ul>
        <ul>
          <li class="mb">
            <div class="time">8:30-12:00</div>
            <div class="arrange-content">
              8:30开始入场，8:50开始上午课程
            </div>
          </li>
          <li class="mb">
            <div class="time">13:50-18:00</div>
            <div class="arrange-content">
              13:50下午课程入场，14:00开始下午课程<br />
              18:00课程结束
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: ''
}
</script>

<style lang="less" scoped>
.member-schedule-container {
  padding: 64px 25px 52px;
  .title {
    font-size: 18px;
    font-weight: 700;
    color: #010343;
    line-height: 25px;
    text-align: center;
  }
  .under-line {
    width: 171px;
    border-bottom: 2px solid #010343;
    margin: 0 auto;
  }
  .content {
    display: flex;
    margin-top: 30px;
  }
  .time-line {
    width: 62px;
    height: 696px;
    background: url(~@/assets/images/member/member-timeline.png) no-repeat;
    background-size: cover;
    margin-right: 8px;
  }
  .arrange {
    ul {
      &.mb72 {
        margin-bottom: 100px;
      }
    }
    li {
      width: 255px;
      background: #f7f9fd;
      padding: 7px 12px 7px 21px;
      &.mb {
        margin-bottom: 22px;
      }
    }
    .time {
      font-size: 18px;
      font-weight: 700;
      line-height: 25px;
    }
    .arrange-content {
      width: 222px;
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
      margin-top: 10px;
      margin-bottom: 2px;
    }
  }
}
</style>
